import { DisplayColumnDef } from '@tanstack/react-table';
import { Column } from './TableUi/types';

export const TABLE_NAME = 'my-offers';

export const getMyContentColumns = (): Record<string, DisplayColumnDef<Column>> => ({
  status: {
    id: 'status',
    header: 'Status',
    size: 65,
  },
  type: {
    id: 'type',
    header: 'Content type',
    size: 50,
  },
  name: {
    id: 'name',
    header: 'Name',
    size: 100,
  },
  createdAt: {
    id: 'createdAt',
    header: 'Date Created',
    size: 50,
  },
  offerCreatedAt: {
    id: 'offerCreatedAt',
    header: 'Date Published',
    size: 50,
  },
  offerUpdatedAt: {
    id: 'offerUpdatedAt',
    header: 'Date Updated',
    size: 50,
  },
  size: {
    id: 'size',
    header: 'Size',
    size: 50,
  },
  storage: {
    id: 'storage',
    header: 'Storage',
    size: 50,
  },
  offerId: {
    id: 'offerId',
    header: 'Offer Id',
    size: 40,
  },
  edit: {
    id: 'edit',
    header: '',
    size: 66,
    minSize: 66,
    meta: {
      show: true,
      ellipsis: false,
    },
  },
});

import { MigratorApp, Meta as MetaCommon } from 'utils/migrator/MigratorApp';
import { SlotMetaV1Schema, SlotMetaV1 } from './v1';

export type SlotMetaVersions = {
  1: SlotMetaV1;
};

const APP_NAME = 'MARKETPLACE_AI';

export type Meta = MetaCommon<SlotMetaVersions>;

export class SlotMetaMigrator extends MigratorApp<SlotMetaVersions> {
  public readonly APP_NAME = APP_NAME;

  protected get latestVersion(): keyof SlotMetaVersions {
    return 1;
  }

  protected get migrations() {
    return {};
  }

  protected get schemas() {
    return {
      1: SlotMetaV1Schema,
    };
  }
}
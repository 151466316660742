import {
  createCipheriv,
  randomBytes,
  CipherCCM,
  Cipher,
  CipherGCMOptions,
  Decipher,
  DecipherGCM,
  createDecipheriv,
} from 'crypto-browserify';
import {
  Encryption, EncryptionWithIV, Encoding, EncryptionWithMacIV,
} from '@super-protocol/dto-js';
import { cryptoUtils } from '@super-protocol/sdk-js';

export interface EncryptStreamResult {
  stream: ReadableStream<Uint8Array>;
  encryption: EncryptionWithIV;
  getAuthTag: () => Buffer | null;
}

export interface DecryptStreamResult {
  stream: ReadableStream<Uint8Array>;
}

export class CryptoBrowserify {
  public static getKeyLength(cipher: string): number {
    if (/256-xts/.test(cipher)) {
      return 64;
    }
    if (/256|128-xts|chacha20/.test(cipher) && cipher !== 'aes-128-cbc-hmac-sha256') {
      return 32;
    }
    if (/192|des-ede3|desx|des3$/.test(cipher) || cipher === 'id-smime-alg-cms3deswrap') {
      return 24;
    }
    if (/128|des-ede/.test(cipher)) {
      return 16;
    }
    if (/64|des/.test(cipher)) {
      return 8;
    }
    if (/40/.test(cipher)) {
      return 5;
    }
    return 16;
  }

  public static createKey(cipher: string): Buffer {
    const length: number = this.getKeyLength(cipher);
    return randomBytes(length);
  }

  public static getIVLength(cipher: string): number {
    if (cryptoUtils.isCCM(cipher) || cryptoUtils.isGCM(cipher) || cryptoUtils.isOCB(cipher)) {
      return 12;
    }
    if (/wrap-pad/.test(cipher)) {
      return 4;
    }
    if (/wrap|cast|des|bf|blowfish|idea|rc2/.test(cipher)) {
      return 8;
    }
    return 16;
  }

  public static createCipher(cipher: string, key: Buffer, iv: Buffer): Cipher {
    if (cryptoUtils.isECB(cipher) || cryptoUtils.isRC4(cipher)) {
      throw new Error(`Cipher "${cipher}" is not supported`);
    }

    if (cryptoUtils.isCCM(cipher) || cryptoUtils.isOCB(cipher)) {
      const options: CipherGCMOptions = {
        authTagLength: 16,
      };
      return createCipheriv(cipher, key, iv, options);
    }
    return createCipheriv(cipher, key, iv);
  }

  public static createDecipher(cipher: string, key: Buffer, iv: Buffer, mac?: Buffer): Decipher {
    const options: CipherGCMOptions = {};
    if (cryptoUtils.isCCM(cipher) || cryptoUtils.isOCB(cipher)) {
      options.authTagLength = 16;
    }
    const decipher: DecipherGCM = createDecipheriv(cipher, key, iv, options) as DecipherGCM;
    if (mac) {
      decipher.setAuthTag(mac);
    }
    return decipher;
  }

  public static createIV(cipher: string): Buffer {
    const length: number = this.getIVLength(cipher);
    return randomBytes(length);
  }

  public static async encryptStream(
    readableStrem: ReadableStream<Uint8Array>,
    encryptionProp: Encryption | EncryptionWithIV,
  ): Promise<EncryptStreamResult> {
    const {
      key, algo, encoding = Encoding.base64, cipher,
    } = encryptionProp;
    const { iv: ivProp } = encryptionProp as EncryptionWithIV;
    if (!key) throw new Error('key required');
    if (!cipher) throw new Error('cipher required');
    if (!algo) throw new Error('algo required');
    const iv = ivProp ? Buffer.from(ivProp, encoding) : this.createIV(cipher);
    const cipherIv = this.createCipher(cipher, Buffer.from(key, encoding), iv);

    const encryptStream = new TransformStream<Uint8Array, Uint8Array>({
      async transform(chunk, controller) {
        try {
          controller.enqueue(new Uint8Array(cipherIv.update(chunk)));
        } catch (error) {
          console.error('Stream encryption transform error: ', error);
          controller.terminate();
        }
      },
      async flush(controller) {
        try {
          controller.enqueue(new Uint8Array(cipherIv.final()));
        } catch (error) {
          console.error('Stream encryption flush error: ', error);
          controller.terminate();
        }
      },
    });

    const encryption: EncryptionWithIV = {
      algo,
      iv: iv.toString(encoding),
      encoding,
      key,
      cipher,
    };

    const getAuthTag = () => (cryptoUtils.isAuthTagRequired(cipher) ? (cipherIv as CipherCCM)?.getAuthTag?.() : null);

    return { stream: readableStrem.pipeThrough(encryptStream), encryption, getAuthTag };
  }

  public static async decryptStream(
    readableStrem: ReadableStream<Uint8Array>,
    encryptionProp: EncryptionWithMacIV | EncryptionWithIV,
  ): Promise<DecryptStreamResult> {
    const {
      key, algo, encoding = Encoding.base64, cipher, iv,
    } = encryptionProp;
    const { mac } = encryptionProp as EncryptionWithMacIV;
    if (!key) throw new Error('key required');
    if (!cipher) throw new Error('cipher required');
    if (!algo) throw new Error('algo required');

    const decipher: Decipher = this.createDecipher(
      cipher,
      Buffer.from(key, encoding),
      Buffer.from(iv, encoding),
      mac ? Buffer.from(mac, encoding) : undefined
    );

    const decryptStream = new TransformStream<Uint8Array, Uint8Array>({
      async transform(chunk: Uint8Array, controller: TransformStreamDefaultController) {
        controller.enqueue(new Uint8Array(decipher.update(chunk)));
      },
      async flush(controller: TransformStreamDefaultController) {
        controller.enqueue(new Uint8Array(decipher.final()));
      },
    });

    return { stream: readableStrem.pipeThrough(decryptStream) };
  }
}
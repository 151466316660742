import { BuildOrderForm } from 'lib/features/createOrderV2/types';
import { getOffersAndTeeOffersIds } from 'lib/features/createOrderV2/helpers';
import {
  OfferFilter, TOfferType, ConnectionArgs, SortDir, ValueOfferSubtype,
} from 'generated/types';
import {
  FormControlPanel,
  FiltersFields,
  Blocks,
  Compatible,
  FiltersState,
  Offers,
  Pages,
  FormModels,
  FormFiltersModels,
  ControlPanelFields,
  Sorting,
  FormFiltersDatasets,
  FormDatasets,
  Variables,
  ViewType,
  Verified,
} from './types';

export const getPreparedSortingModels = (controlPanel?: FormControlPanel | null): ConnectionArgs => {
  switch (controlPanel?.[ControlPanelFields.sorting]) {
    case Sorting.newest:
      return { sort: [{ sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc }] };
    default:
      return { sort: [{ sortBy: 'price', sortDir: SortDir.Asc }] };
  }
};

export const getPreparedSortingDatasets = (controlPanel?: FormControlPanel | null): ConnectionArgs => {
  switch (controlPanel?.[ControlPanelFields.sorting]) {
    case Sorting.newest:
      return { sort: [{ sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc }] };
    default:
      return { sort: [{ sortBy: 'price', sortDir: SortDir.Asc }] };
  }
};

export const getPreparedPaginationDatasets = (formDatasets: FormDatasets): ConnectionArgs => ({
  ...getPreparedSortingDatasets(formDatasets.controlPanel),
  first: 15,
});

export const getPreparedPaginationModels = (formModels: FormModels): ConnectionArgs => ({
  ...getPreparedSortingModels(formModels.controlPanel),
  first: 15,
});

const getVerifiedFilterValue = (value?: Verified): boolean | undefined => {
  if (!value) return undefined;
  const verifiedMap = {
    [Verified.verified]: true,
    [Verified.unsorted]: false,
    [Verified.all]: undefined,
  }
  return verifiedMap[value];
}

export const getPreparedFiltersDatasets = (
  filters?: FormFiltersModels,
  buildOrderForm?: BuildOrderForm,
): OfferFilter => {
  const { compatible, categories, search } = (filters || {});
  const searchPrepared = search ? search.trim() : '';
  const selectedOfferIds = getOffersAndTeeOffersIds({ datasets: buildOrderForm?.datasets })?.offers;
  return {
    offerType: TOfferType.Data,
    ...(compatible !== Compatible.all && selectedOfferIds?.length ? { selectedOfferIds } : {}),
    inactive: false,
    enabled: true,
    subType: ValueOfferSubtype.ValueSubtypeDataset,
    ...(categories ? { configuration: { attributes: { datasets: { tasks: { pipelineType: categories } } } } } : {}),
    ...(searchPrepared ? { search: searchPrepared } : {}),
  };
};

export const getPreparedFiltersModels = (
  filters?: FormFiltersModels,
  buildOrderForm?: BuildOrderForm,
): OfferFilter => {
  const {
    compatible, categories, search, verified,
  } = (filters || {});
  const searchPrepared = search ? search.trim() : '';
  const selectedOfferIds = getOffersAndTeeOffersIds({ model: buildOrderForm?.model })?.offers;
  return {
    offerType: TOfferType.Data,
    ...(compatible !== Compatible.all && selectedOfferIds?.length ? { selectedOfferIds } : {}),
    inactive: false,
    enabled: true,
    subType: ValueOfferSubtype.ValueSubtypeModel,
    verified: getVerifiedFilterValue(verified), 
    ...(categories ? { configuration: { attributes: { models: { task: { pipelineType: categories } } } } } : {}),
    ...(searchPrepared ? { search: searchPrepared } : {}),
  };
};

export const getPreparedFormModels = (formModels: FormModels, buildOrderForm?: BuildOrderForm): Variables => ({
  filter: getPreparedFiltersModels(formModels.filters || undefined, buildOrderForm),
  pagination: getPreparedPaginationModels(formModels),
});

export const getPreparedFormDatasets = (formModels: FormDatasets, buildOrderForm?: BuildOrderForm): Variables => ({
  filter: getPreparedFiltersDatasets(formModels.filters || undefined, buildOrderForm),
  pagination: getPreparedPaginationDatasets(formModels),
});

export const getPreparedForm = (
  page: Pages,
  form: FormModels | FormDatasets,
  buildOrderForm?: BuildOrderForm,
): Variables => {
  const forms = {
    [Pages.datasets]: getPreparedFormDatasets,
    [Pages.models]: getPreparedFormModels,
  };
  return forms[page]?.(form, buildOrderForm);
};

export const getInitialControlPanelDatasets = (): FormControlPanel => {
  return {
    [ControlPanelFields.sorting]: Sorting.newest,
    [ControlPanelFields.view]: ViewType.cards,
  };
};

export const getInitialFiltersDatasets = (): FormFiltersDatasets => {
  return {
    [FiltersFields.compatible]: Compatible.all,
    [FiltersFields.offerType]: TOfferType.Data,
    [FiltersFields.offers]: Offers.sp,
    [FiltersFields.categories]: [],
    [FiltersFields.verified]: Verified.all,
    [FiltersFields.search]: '',
  };
};

export const getInitialValuesDatasets = (
  props?: FormDatasets,
): FormDatasets => {
  const { filters, controlPanel } = props || {};
  return {
    [Blocks.filters]: { ...(filters ?? getInitialFiltersDatasets()) },
    [Blocks.controlPanel]: controlPanel || getInitialControlPanelDatasets(),
  };
};

export const getInitialControlPanelModels = (): FormControlPanel => {
  return {
    [ControlPanelFields.sorting]: Sorting.newest,
    [ControlPanelFields.view]: ViewType.cards,
  };
};

export const getInitialFiltersModels = (): FormFiltersModels => {
  return {
    [FiltersFields.compatible]: Compatible.all,
    [FiltersFields.offerType]: TOfferType.Data,
    [FiltersFields.offers]: Offers.sp,
    [FiltersFields.categories]: [],
    [FiltersFields.verified]: Verified.all,
    [FiltersFields.search]: '',
  };
};

export const getInitialValuesModels = (
  props?: FormModels,
): FormModels => {
  const { filters, controlPanel } = props || {};
  return {
    [Blocks.filters]: { ...(filters ?? getInitialFiltersModels()) },
    [Blocks.controlPanel]: controlPanel || getInitialControlPanelModels(),
  };
};

export const getInitialState = (): FiltersState => ({
  [Pages.models]: getInitialValuesModels(),
  [Pages.datasets]: getInitialValuesDatasets(),
});

export const getInitialFilters = (page: Pages): FormFiltersDatasets | FormFiltersModels | null => {
  switch (page) {
    case Pages.models:
      return getInitialFiltersModels();
    case Pages.datasets:
      return getInitialFiltersDatasets();
    default:
      return null;
  }
};
import set from 'lodash.set';
import { Offer, OfferSlot, OfferVersion } from 'generated/types';
import { OfferMetaMigrator } from './offerMeta/OfferMetaMigrator';
import { SlotMetaMigrator } from './slotMeta/SlotMetaMigrator';
import { TransformedOffer, TransformedOfferSlot, TransformedOfferVersion } from './types';

export const transformOfferVersionMetadata = (offerVersion: OfferVersion): TransformedOfferVersion => {
  let transformedMetadata: ReturnType<OfferMetaMigrator['getMigratedAppData']>;
  try {
    transformedMetadata = new OfferMetaMigrator().getMigratedAppData(offerVersion.info.metadata);
  } catch (e) {
    console.error('Error transform offer version metadata: ', e);
  }
  return set(offerVersion, 'info.transformedMetadata', transformedMetadata);
};

export const transformSlotMetadata = (offerSlot: OfferSlot): TransformedOfferSlot => {
  let transformedMetadata: ReturnType<SlotMetaMigrator['getMigratedAppData']>;
  try {
    transformedMetadata = new SlotMetaMigrator().getMigratedAppData(offerSlot.metadata);
  } catch (e) {
    console.error('Error transform slot metadata: ', e);
  }
  return set(offerSlot, 'transformedMetadata', transformedMetadata);
};

export const transformOffer = (offer: Offer | null): TransformedOffer | null => {
  if (!offer) return null;
  return {
    ...offer,
    versions: (offer.versions || []).map(transformOfferVersionMetadata),
    slots: (offer.slots || []).map(transformSlotMetadata),
  };
};

export const transformOfferResponse = (response: { offer: Offer }): { offer: TransformedOffer | null } | null => {
  if (!response) return null;
  return {
    ...response,
    offer: transformOffer(response.offer),
  };
};
import * as Yup from 'yup';

export type OfferMetaV1 = {
  url?: string;
};

export const OfferMetaV1Schema: Yup.SchemaOf<OfferMetaV1> = Yup
  .object({
    url: Yup.string(),
  })
  .default(undefined).optional();
/* eslint-disable prefer-destructuring */
import { OrderStatus } from '@super-protocol/sdk-js';
import {
  Encryption,
  EncryptionWithMacIV,
  StorageProviderResource,
  StorageType,
} from '@super-protocol/dto-js';
import { CryptoFile } from 'utils/crypto/CryptoFile';
import { getLibarchivejs } from 'utils/libarchivejs';
import { getReadS3Config } from 'utils/S3/utils';
import getConfig from 'config';
import { downloadFile as downloadFileS3 } from 'utils/S3/S3';
import {
  getFileFromArrayBuffer, getTextFromFile,
} from 'utils/file';
import { CryptoUtils } from 'utils/crypto/CryptoUtils';
import { JSONParseSafe } from 'utils';
import { streamToString } from 'utils/stream';
import { downloadFile } from 'utils/downloader';
import { ErrorDecription, EncryptedResult, EcryptOrderResult } from './types';

export type Response = { error: string; }

export const getFileName = (filepath: string, orderId: string) => {
  const splitedFileName = filepath.replace('.encrypted', '').split('.');
  splitedFileName[0] = `${splitedFileName[0]}_${orderId}`;
  return splitedFileName.join('.');
};

export const getArchiveResultText = async (arrayBuffer: ArrayBuffer, fileName: string): Promise<string> => {
  const libarchivejs = await getLibarchivejs();
  const file = getFileFromArrayBuffer(arrayBuffer, fileName);
  const openedArchive = await libarchivejs.open(file);
  const files = await openedArchive.extractFiles();
  const resultJson = files?.output?.['result.json'];
  if (!resultJson) return '';
  return getTextFromFile(resultJson);
};

const tryDecryptWithKeys = async (
  encryption: Encryption,
  decryptionKeys: string[],
): Promise<string> => {
  if (!encryption) {
    throw new Error('Encrypted data required');
  }

  if (!decryptionKeys?.length) {
    throw new Error('Decryption keys required');
  }

  const { Crypto } = await import('@super-protocol/sdk-js');
  let error: Error | undefined;

  for (let i = 0; i < decryptionKeys.length; i++) {
    const decryptionKey = decryptionKeys[i];
    try {
      encryption.key = decryptionKey;
      return await Crypto.decrypt(encryption);
    } catch (e) {
      error = e as Error;
    }
  }

  if (error) {
    throw error;
  }

  return '';
};

export const decryptOrderResult = async (
  orderId: string,
  phrase: string,
  encryptedResult?: string,
): Promise<EcryptOrderResult> => {
  let privateKeyBase64 = phrase;
  if (phrase.length > CryptoUtils.MAX_LENGTH_BASE64_MNEMONIC) {
    privateKeyBase64 = (await CryptoUtils.generateKeysByMnemonic(phrase))?.privateKeyBase64;
  }

  if (!encryptedResult) {
    throw new Error('Order encrypted result required');
  }

  if (!privateKeyBase64) {
    throw new Error('Private key required');
  }

  const COMMON_ERROR_PHRASE = 'Unable to decrypt order result with this passphrase';
  let decrypted = '';
  const encryptedObj: EncryptedResult | Encryption | null = JSONParseSafe(encryptedResult);

  if (!encryptedResult) {
    throw new Error(COMMON_ERROR_PHRASE);
  }

  if (!encryptedObj) {
    return {
      isFile: false,
      content: encryptedResult,
      isPlaneText: true,
    };
  }

  const { RIGenerator, Crypto } = await import('@super-protocol/sdk-js');
  const derivedPrivateKey = (await RIGenerator.getDerivedPrivateKey({
    ...(encryptedObj as EncryptedResult)?.encryption || encryptedObj,
    key: Crypto.getPublicKey({ ...(encryptedObj as EncryptedResult)?.encryption || encryptedObj, key: privateKeyBase64 }).key,
  }));

  if ((encryptedObj as EncryptedResult)?.resource && (encryptedObj as EncryptedResult)?.encryption) {
    const decryptedResource = await tryDecryptWithKeys(
      (encryptedObj as EncryptedResult).resource,
      [privateKeyBase64, derivedPrivateKey.key],
    );

    const decryptedEncryption = await tryDecryptWithKeys(
      (encryptedObj as EncryptedResult).encryption,
      [privateKeyBase64, derivedPrivateKey.key],
    );

    if (!decryptedResource || !decryptedEncryption) {
      throw new Error(COMMON_ERROR_PHRASE);
    }
    decrypted = `{ "resource": ${decryptedResource}, "encryption": ${decryptedEncryption} }`;
  } else {
    decrypted = await tryDecryptWithKeys(encryptedObj as unknown as Encryption, [privateKeyBase64, derivedPrivateKey.key]) || '';
    if (!decrypted) {
      throw new Error(COMMON_ERROR_PHRASE);
    }

    const decryptedResult: ErrorDecription = JSON.parse(decrypted);

    if (decryptedResult?.name?.indexOf?.('Error') !== -1) {
      const { Order } = await import('@super-protocol/sdk-js');
      const order = new Order(orderId);
      const orderInfo = await order?.getOrderInfo();
      if (orderInfo?.status === OrderStatus.Error) {
        throw new Error(decryptedResult?.message || COMMON_ERROR_PHRASE);
      } else if (decryptedResult?.message) {
        decrypted = decryptedResult.message;
      } else {
        return {
          isFile: false,
          content: JSON.stringify(decryptedResult, null, 2),
        };
      }
    }
  }

  if (!decrypted) {
    throw new Error(COMMON_ERROR_PHRASE);
  }

  const decryptedObj: { resource: StorageProviderResource, encryption: EncryptionWithMacIV } = JSON.parse(decrypted);

  const { resource, encryption } = decryptedObj || {};
  const { filepath } = resource || {};

  if (!filepath) {
    return {
      isFile: false,
      content: JSON.stringify(decryptedObj, null, 2),
    };
  }

  const { prefix, bucket } = resource.credentials;

  // todo get credentials from order result
  const {
    NEXT_PUBLIC_S3_READ_ACCESS_KEY_ID,
    NEXT_PUBLIC_S3_READ_SECRET_KEY,
    NEXT_PUBLIC_S3_REGION,
  } = getConfig();
  //

  const stream = await downloadFileS3({
    path: filepath,
    s3: getReadS3Config({
      s3Credentials: {
        readAccessKeyId: NEXT_PUBLIC_S3_READ_ACCESS_KEY_ID,
        readSecretAccessKey: NEXT_PUBLIC_S3_READ_SECRET_KEY,
        region: NEXT_PUBLIC_S3_REGION,
      },
      storageType: StorageType.S3,
      bucket,
      prefix,
    }),
  });

  if (!stream) throw new Error('Download file error');

  const ciphertext = await streamToString(stream, 'base64');
  const decryptedFileContent = await CryptoFile.decrypt({ ...encryption, ciphertext });
  const fileName = getFileName(filepath, orderId);
  const arrayBuffer = Buffer.from(decryptedFileContent, 'binary');
  const text = await getArchiveResultText(arrayBuffer, fileName);

  return {
    isFile: true,
    content: text,
    download: async () => downloadFile(arrayBuffer, fileName, 'application/gzip'),
  };
};

import { getBaseQuery } from 'connectors/sp';
import { Offer } from 'generated/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  OffersQuery,
  OffersQueryVariables,
  OffersDocument,
  OfferQueryVariables,
  OfferDocument,
  OfferTypeDocument,
  OffersRestrictionsDocument,
  OfferTypeQuery,
  OfferTypeQueryVariables,
  OffersRestrictionsQuery,
  OffersRestrictionsQueryVariables,
} from 'generated/schemas/offers';
import { transformOfferResponse } from './transformOffer';
import { TransformedOffer } from './types';

export const offers = createApi({
  reducerPath: 'offers',
  baseQuery: getBaseQuery(),
  endpoints: (build) => ({
    getOffers: build.query<OffersQuery, OffersQueryVariables>({
      query: (variables) => ({ document: OffersDocument, variables }),
    }),
    getOffer: build.query<{ offer: TransformedOffer | null } | null, OfferQueryVariables>({
      query: (variables) => ({ document: OfferDocument, variables }),
      transformResponse: (response: { offer: Offer }) => transformOfferResponse(response),
    }),
    getOffersRestrictions: build.query<OffersRestrictionsQuery, OffersRestrictionsQueryVariables>({
      query: (variables) => ({ document: OffersRestrictionsDocument, variables }),
    }),
    getOfferType: build.query<OfferTypeQuery, OfferTypeQueryVariables>({
      query: (variables) => ({ document: OfferTypeDocument, variables }),
    }),
  }),
});

export const {
  useGetOffersQuery,
  useLazyGetOffersQuery,
  useGetOfferQuery,
  useLazyGetOfferQuery,
  useLazyGetOfferTypeQuery,
  useGetOfferTypeQuery,
  useLazyGetOffersRestrictionsQuery,
  useGetOffersRestrictionsQuery,
} = offers;

import { Encoding, Hash, HashAlgorithm } from '@super-protocol/dto-js';

export const getBinaryStringFromFile = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

export const getFileFromArrayBuffer = (arrayBuffer: ArrayBuffer, name: string, type = 'application/x-gzip'): File => {
  return new File([arrayBuffer], name, {
    type,
  });
};

export const getArrayBufferFromFile = (file: File): Promise<ArrayBuffer> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => resolve(new Uint8Array(reader.result as any) as ArrayBuffer);
  reader.onerror = (error) => reject(error);
});

export const getTextFromFile = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = (e) => resolve((e.target?.result as string) || '');
  reader.onerror = (error) => reject(error);
});

export const fileToSha256Hex = async (file: File): Promise<Hash | null> => {
  const arrayBuffer = await file.arrayBuffer();
  const crypto: null | SubtleCrypto = window?.crypto?.subtle ?? null;
  if (!crypto) return null;
  const buf = await crypto.digest('SHA-256', arrayBuffer);
  const rawHash = new Uint8Array(buf);
  return {
    algo: HashAlgorithm.SHA256,
    hash: Buffer.from(rawHash).toString('hex'),
    encoding: Encoding.hex,
  };
};

export const getFileExtension = (filename?: string): string | null => {
  if (!filename) return null;
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop() || null : null;
};

export const getFileDirectory = (path?: string): string => {
  if (!path) return '';
  const parts = path.split('/');
  return parts[parts.length - 2] || '';
};
import {
  createSlice,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions, hydrate } from 'lib/features/helpers';
import {
  FiltersState, Pages, FormDatasets, FormModels, ViewType, Blocks, FormControlPanel,
} from './types';
import { Storage } from '../types';
import { getInitialState } from './helpers';

const initialState: FiltersState = getInitialState();

const NAME = 'shopwindow';

export const shopwindow = createSlice({
  name: NAME,
  initialState,
  reducers: {
    updatePageFilters(state, action: PayloadAction<{ page: Pages, filters: FormDatasets | FormModels | null }>) {
      const { page, filters } = action.payload || {};
      if (page && filters) {
        state[page] = filters;
      }
    },
    updateViewType(state, action: PayloadAction<{ page: Pages, viewType: ViewType }>) {
      const { page, viewType } = action.payload || {};
      if (viewType && state?.[page]?.[Blocks.controlPanel]) {
        (state[page][Blocks.controlPanel] as FormControlPanel).view = viewType;
      }
    },
  },
  extraReducers(builder) {
    hydrate(builder, NAME);
  },
  selectors: {
    filtersSelector: (state) => (state),
  },
});

export const {
  updatePageFilters,
  updateViewType,
} = shopwindow.actions;

export const {
  filtersSelector,
} = shopwindow.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'shopwindow', storage,
}, shopwindow.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(shopwindow);

import { MigratorApp, Meta as MetaCommon } from 'utils/migrator/MigratorApp';
import { OfferMetaV1Schema, OfferMetaV1 } from './v1';

export type OfferMetaVersions = {
  1: OfferMetaV1;
};

const APP_NAME = 'MARKETPLACE_AI';

export type Meta = MetaCommon<OfferMetaVersions>;

export class OfferMetaMigrator extends MigratorApp<OfferMetaVersions> {
  public readonly APP_NAME = APP_NAME;

  protected get latestVersion(): keyof OfferMetaVersions {
    return 1;
  }

  protected get migrations() {
    return {};
  }

  protected get schemas() {
    return {
      1: OfferMetaV1Schema,
    };
  }
}
import * as Yup from 'yup';

export type SlotMetaV1 = {
  name?: string;
  parameters?: string;
  quantization?: string;
  format?: string;
};

export const SlotMetaV1Schema: Yup.SchemaOf<SlotMetaV1> = Yup
  .object({
    name: Yup.string(),
    parameters: Yup.string(),
    quantization: Yup.string(),
    format: Yup.string(),
  })
  .default(undefined).optional();
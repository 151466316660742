import { memo, forwardRef } from 'react';

export const genericMemo: <T>(component: T) => T = memo;

export const generateRandomArray = (count: number): number[] => [...Array(count).keys()];

export const forwardRefWithGenerics = forwardRef as <
	T,
	P = NonNullable<unknown>,
>(
	render: (
		props: P,
		ref: React.ForwardedRef<T>,
	) => ReturnType<React.FunctionComponent>,
) => (
	props: React.PropsWithoutRef<P> & React.RefAttributes<T>,
) => ReturnType<React.FunctionComponent>;

export const memoWithGenerics = memo as <P extends object>(
	Component: (props: P) => ReturnType<React.FunctionComponent>,
	propsAreEqual?: (
		prevProps: Readonly<P>,
		nextProps: Readonly<P>,
	) => boolean,
) => (props: P) => ReturnType<React.FunctionComponent>;
import * as Types from '../types';

import { api } from '../../connectors/sp';
export type PageDataDtoFragmentFragment = { __typename?: 'PageDataDto', count: number, limit: number, offset: number };

export type OffersQueryVariables = Types.Exact<{
  pagination: Types.ConnectionArgs;
  filter?: Types.InputMaybe<Types.OfferFilter>;
}>;


export type OffersQuery = { __typename?: 'Query', result: { __typename?: 'ListOffersResponse', pageData?: { __typename?: 'PageDataDto', count: number, limit: number, offset: number } | null, page: { __typename?: 'OfferConnection', pageInfo?: { __typename?: 'OfferPageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'OfferEdge', cursor?: string | null, node?: { __typename?: 'Offer', _id: string, id: string, enabled: boolean, inactive?: boolean | null, configuration?: any | null, verifiedAt?: any | null, verifiedBy?: string | null, offerInfo: { __typename?: 'OfferInfo', offerType: string, subType: string, cancelable: boolean, description: string, name: string, restrictions?: { __typename?: 'OfferRestrictions', offers?: Array<string> | null, types?: Array<string> | null } | null }, origins?: { __typename?: 'Origins', createdBy: string, createdDate: number, modifiedBy: string, modifiedDate: number } | null, providerInfo: { __typename?: 'ProviderInformation', actionAccount: string, description: string, name: string, tokenReceiver: string }, slots: Array<{ __typename?: 'OfferSlot', id: string, info: { __typename?: 'SlotInfo', cpuCores: number, diskUsage: number, ram: number, vram: number, gpuCores: number }, option: { __typename?: 'OptionInfo', bandwidth: number, externalPort: number, traffic: number }, usage: { __typename?: 'SlotUsage', maxTimeMinutes: number, minTimeMinutes: number, price: string, priceType: Types.PriceType } }> } | null }> | null } } };

export type OffersRestrictionsQueryVariables = Types.Exact<{
  pagination: Types.ConnectionArgs;
  filter?: Types.InputMaybe<Types.OfferFilter>;
}>;


export type OffersRestrictionsQuery = { __typename?: 'Query', result: { __typename?: 'ListOffersResponse', page: { __typename?: 'OfferConnection', edges?: Array<{ __typename?: 'OfferEdge', node?: { __typename?: 'Offer', _id: string, id: string, offerInfo: { __typename?: 'OfferInfo', restrictions?: { __typename?: 'OfferRestrictions', offers?: Array<string> | null, types?: Array<string> | null } | null } } | null }> | null } } };

export type OfferQueryVariables = Types.Exact<{
  _id: Types.Scalars['String']['input'];
  mapTo?: Types.InputMaybe<Types.TIdSource>;
}>;


export type OfferQuery = { __typename?: 'Query', offer: { __typename?: 'Offer', _id: string, id: string, disabledAfter: number, enabled: boolean, inactive?: boolean | null, configuration?: any | null, verifiedAt?: any | null, verifiedBy?: string | null, offerInfo: { __typename?: 'OfferInfo', offerType: string, subType: string, cancelable: boolean, description: string, name: string, restrictions?: { __typename?: 'OfferRestrictions', offers?: Array<string> | null, types?: Array<string> | null } | null }, origins?: { __typename?: 'Origins', createdBy: string, createdDate: number, modifiedBy: string, modifiedDate: number } | null, providerInfo: { __typename?: 'ProviderInformation', actionAccount: string, description: string, name: string, tokenReceiver: string }, slots: Array<{ __typename?: 'OfferSlot', id: string, metadata?: any | null, info: { __typename?: 'SlotInfo', cpuCores: number, diskUsage: number, ram: number, vram: number, gpuCores: number }, option: { __typename?: 'OptionInfo', bandwidth: number, externalPort: number, traffic: number }, usage: { __typename?: 'SlotUsage', maxTimeMinutes: number, minTimeMinutes: number, price: string, priceType: Types.PriceType } }>, versions?: Array<{ __typename?: 'OfferVersion', version: number, info: { __typename?: 'OfferVersionInfo', metadata?: any | null } }> | null } };

export type OfferTypeQueryVariables = Types.Exact<{
  _id: Types.Scalars['String']['input'];
  mapTo?: Types.InputMaybe<Types.TIdSource>;
}>;


export type OfferTypeQuery = { __typename?: 'Query', offerType: Types.OfferType };

export type OffersModerationQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OfferFilter>;
}>;


export type OffersModerationQuery = { __typename?: 'Query', result: { __typename?: 'ModerationStatsResponse', unverified: number, verified: number } };

export const PageDataDtoFragmentFragmentDoc = `
    fragment PageDataDtoFragment on PageDataDto {
  count
  limit
  offset
}
    `;
export const OffersDocument = `
    query Offers($pagination: ConnectionArgs!, $filter: OfferFilter) {
  result: offers(pagination: $pagination, filter: $filter) {
    pageData {
      ...PageDataDtoFragment
    }
    page {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          _id
          id
          enabled
          inactive
          offerInfo {
            offerType
            subType
            cancelable
            description
            name
            restrictions {
              offers
              types
            }
          }
          configuration
          origins {
            createdBy
            createdDate
            modifiedBy
            modifiedDate
          }
          providerInfo {
            actionAccount
            description
            name
            tokenReceiver
          }
          slots {
            id
            info {
              cpuCores
              diskUsage
              ram
              vram
              gpuCores
            }
            option {
              bandwidth
              externalPort
              traffic
            }
            usage {
              maxTimeMinutes
              minTimeMinutes
              price
              priceType
            }
          }
          verifiedAt
          verifiedBy
        }
        cursor
      }
    }
  }
}
    ${PageDataDtoFragmentFragmentDoc}`;
export const OffersRestrictionsDocument = `
    query OffersRestrictions($pagination: ConnectionArgs!, $filter: OfferFilter) {
  result: offers(pagination: $pagination, filter: $filter) {
    page {
      edges {
        node {
          _id
          id
          offerInfo {
            restrictions {
              offers
              types
            }
          }
        }
      }
    }
  }
}
    `;
export const OfferDocument = `
    query Offer($_id: String!, $mapTo: TIdSource) {
  offer(_id: $_id, mapTo: $mapTo) {
    _id
    id
    disabledAfter
    enabled
    inactive
    offerInfo {
      offerType
      subType
      cancelable
      description
      name
      restrictions {
        offers
        types
      }
    }
    configuration
    origins {
      createdBy
      createdDate
      modifiedBy
      modifiedDate
    }
    providerInfo {
      actionAccount
      description
      name
      tokenReceiver
    }
    slots {
      id
      info {
        cpuCores
        diskUsage
        ram
        vram
        gpuCores
      }
      option {
        bandwidth
        externalPort
        traffic
      }
      usage {
        maxTimeMinutes
        minTimeMinutes
        price
        priceType
      }
      metadata
    }
    versions {
      info {
        metadata
      }
      version
    }
    verifiedAt
    verifiedBy
  }
}
    `;
export const OfferTypeDocument = `
    query OfferType($_id: String!, $mapTo: TIdSource) {
  offerType(_id: $_id, mapTo: $mapTo)
}
    `;
export const OffersModerationDocument = `
    query OffersModeration($filter: OfferFilter) {
  result: offersModeration(filter: $filter) {
    unverified
    verified
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    Offers: build.query<OffersQuery, OffersQueryVariables>({
      query: (variables) => ({ document: OffersDocument, variables })
    }),
    OffersRestrictions: build.query<OffersRestrictionsQuery, OffersRestrictionsQueryVariables>({
      query: (variables) => ({ document: OffersRestrictionsDocument, variables })
    }),
    Offer: build.query<OfferQuery, OfferQueryVariables>({
      query: (variables) => ({ document: OfferDocument, variables })
    }),
    OfferType: build.query<OfferTypeQuery, OfferTypeQueryVariables>({
      query: (variables) => ({ document: OfferTypeDocument, variables })
    }),
    OffersModeration: build.query<OffersModerationQuery, OffersModerationQueryVariables | void>({
      query: (variables) => ({ document: OffersModerationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useOffersQuery, useLazyOffersQuery, useOffersRestrictionsQuery, useLazyOffersRestrictionsQuery, useOfferQuery, useLazyOfferQuery, useOfferTypeQuery, useLazyOfferTypeQuery, useOffersModerationQuery, useLazyOffersModerationQuery } = injectedRtkApi;

